import BigNumber from "bignumber.js";
export const bnToDec = (bn, decimals = 18, decimalPlace = 2) => {
  let result;
  const thisStr = new BigNumber(bn)
    .dividedBy(new BigNumber(10).pow(new BigNumber(decimals)))
    .toString();
  // num.substring(0,s.indexOf(".")+3); alert(result); }
  if (decimalPlace === -1) {
    result = parseFloat(thisStr)
  } else {
    result =
      thisStr.indexOf(".") !== -1
        ? parseFloat(
          thisStr.substring(0, thisStr.indexOf(".") + decimalPlace + 1)
        )
        : parseFloat(thisStr);
  }
  return result;
};
// 正则 formate number  不进位
export const formateNormalNumber = (number, precision = 4) => {
    try {
      const str = number?.toString()
      switch (precision) {
        case 2:
          return str.replace(/^(.*\..{2}).*$/, "$1");
          break;
        case 4:
          return str.replace(/^(.*\..{4}).*$/, "$1");
          break;
        case 6:
          return str.replace(/^(.*\..{6}).*$/, "$1");
          break;
        case 8:
          return str.replace(/^(.*\..{8}).*$/, "$1");
          break;
        case 16:
          return str.replace(/^(.*\..{16}).*$/, "$1");
          break;
        default:
          return str.replace(/^(.*\..{4}).*$/, "$1");
          break;
      }
    } catch (error) {
      console.log(error)
    }
  }
  // 显示千分位 不进位
  export const formatNumber = (num, point) => {
    try {
      if(point === 0){
        num = num.toFixed(0)
      }
      num = num?.toString()
      let reg = num.indexOf('.') > -1 ? /(\d)(?=(\d{3})+\.)/g : /(\d)(?=(\d{3})+$)/g
      // return num.replace(reg, '$1,').replace(/(?<=\.\d{0}).*$/, '')
      switch (point) {
        case 0:
          return num.replace(reg, '$1,');
          break;
        case 2:
          return num.replace(reg, '$1,').replace(/(?<=\.\d{2}).*$/, '');
          break;
        case 4:
          return num.replace(reg, '$1,').replace(/(?<=\.\d{4}).*$/, '');
          break;
        case 6:
          return num.replace(reg, '$1,').replace(/(?<=\.\d{6}).*$/, '');
          break;
        case 8:
          return num.replace(reg, '$1,').replace(/(?<=\.\d{8}).*$/, '');
          break;
        default:
          return num.replace(reg, '$1,').replace(/(?<=\.\d{2}).*$/, '');
          break;
      }
    } catch (error) {
      console.log(error)
    }
  
  }
  /**
   * @description: 不进位 保留小数位，支持 abbr 简写 和 percent百分数
   * @param {number} number 处理的数值
   * @param {*} minPrecision 最小位数
   * @param {*} maxPrecision 最多位数
   * @param {*} type 默认为 千分位格式化 || type = 'abbr' 为简写表示 || type= 'percent' 为百分比表示
   * @return {*}
   */
  export const _formatNumberNotCarry = (number, type = '', minPrecision = 2, maxPrecision = 2) => {
    const options = {
      minimumFractionDigits: minPrecision,
      maximumFractionDigits: maxPrecision,
    }
    if (type === 'abbr') {
      if (number === 0) {
        return number.toLocaleString(undefined, options)
      }
      const SI_SYMBOLS = ["", "K", "M", "B", "T", "P", "E"];
      const tier = Math.floor(Math.log10(Math.abs(number)) / 3);
      let suffix = SI_SYMBOLS[tier];
      if (tier === -1) {
        suffix = ""
      }
      const scale = 10 ** ((tier === -1 ? 0 : tier) * 3);
      const scaled = number / scale;
      // return scaled.toLocaleString(undefined, options).slice(0, -1) + suffix;
      return formatNumber(scaled, minPrecision) + suffix
    }
    if (type === 'percent') {
      if (Math.abs(number) < 0.0001) {
        return '0.00%'
        // if (number > 0) {
        //   return '< 0.01%'
        // } else {
        //   return '> -0.01%'
        // }
      }
      if (Math.abs(number) > 9999.99) {
        return '> 999,999.00%'
        // if (number > 0) {
        //   return '> 999,999.00%'
        // } else {
        //   return '< -999,999.00%'
        // }
      }
      // return number.toLocaleString('zh', { ...options, style: 'percent' }).slice(0, -2) + '%'
      return formatNumber(number * 10000 / 100, minPrecision) + '%'
    }
    // return number.toLocaleString(undefined, options).slice(0, -1)
    return formatNumber(number, minPrecision)
  }
const zh_CN = {
  Launch_APP:'Launch App',
  ComingSoon:'Coming Soon',
  JoinTwitter:'Join Twitter',
  Community:'社区',
  Twitter:'Twitter',
  Medium:'Medium',
  Telegram:'电报群',
  WeChat:'微信'
};
export default zh_CN;
import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { Tabs, Typography, Statistic } from '@arco-design/web-react';
import { _formatNumberNotCarry,bnToDec } from '../utils'
const TabPane = Tabs.TabPane;
const Home = ({ changeLanguage, locale }) => {
  const intl = useIntl();
  const fmt = intl.formatMessage;
  const countRef = useRef(null);
  const countRef2 = useRef(null);
  const countRef3 = useRef(null);
  const countRef4 = useRef(null);
  const [USXPrice, setUSXPrice] = useState(undefined)
  const [TotalTransactions, setTotalTransactions] = useState(undefined)
  const [TotalUsers, setTotalUsers] = useState(undefined)
  const [TotalCirculation, setTotalCirculation] = useState(undefined)
  const [data, setData] = useState(undefined)
  const [menu, setMenu] = useState(false)
  const [showDetail, setShowDetail] = useState(0);
  const showDetailHandler = (e) => {
    // 点击tr行 显示 Detail 组件, 点击Detail组件禁止冒泡
    console.log(e.target.dataset.id)
    if (!(e.target.dataset.id)) return;
    setShowDetail(Number(e.target.dataset.id))
  }
  const [activeTab, setActiveTab] = useState('3');
  const showMenu = () => {
    setMenu(!menu)
  }
  const Fetch = () => {
    try {
      fetch("https://usx.finance/dashboard/usx/info")
        .then(response => response.json())
        .catch(error => console.error('Error:', error))
        .then(response => {
          if (response?.status === 'OK') {
            setData(response?.data?.total)
            setUSXPrice(response?.data?.total?.price)
            setTotalTransactions(response?.data?.total?.transactions)
            setTotalUsers(response?.data?.total?.users)
            // _formatNumberNotCarry(bnToDec(TotalCirculation,18),'',0,0)
            setTotalCirculation(bnToDec(response?.data?.total?.circulating,18))
          } else {
            setData(undefined)
            setUSXPrice(undefined)
            setTotalTransactions(undefined)
            setTotalUsers(undefined)
            setTotalCirculation(undefined)
          }
        });
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    Fetch()
  }, [])
  useEffect(() => {
    if (!data) return;
    let _TotalTransactions = Number(data?.transactions)
    let _TotalUsers = Number(data?.users)
    let _TotalCirculation = Number(bnToDec(data?.circulating,18))
    window.TotalTransactions = setInterval(() => {
      // const changeVal = Math.floor(Math.random() * 6)
      _TotalTransactions = _TotalTransactions + 1
      setTotalTransactions(_TotalTransactions)
    }, 5000);
    window.TotalUsers = setInterval(() => {
      // const changeVal = Math.floor(Math.random() * 3)
      _TotalUsers = _TotalUsers + 1
      setTotalUsers(_TotalUsers)
    }, 1000*10);
    window.TotalCirculation = setInterval(() => {
      // const changeVal = Math.floor(Math.random() * 192)
      _TotalCirculation = _TotalCirculation + 1
      setTotalCirculation(_TotalCirculation)
    }, 200);
    return () => {
      clearInterval(window.TotalTransactions)
      clearInterval(window.TotalUsers)
      clearInterval(window.TotalCirculation)
    }
  }, [data])
  return (
    <>
      <Container>
        <Header>
          <TopBar>
            <a href='https://usx.finance/' rel="noopener noreferrer" className='logo'>
              <Log src="/images/logo.svg" />
            </a>
            <Nav>
              <ChangeLanguage
              >
                <span>Docs</span>
                <Changelist className={'showList'}>
                  <a href="https://docs.usx.finance/" target="_blank" rel="noopener noreferrer">GitBook</a>
                  <a href="https://github.com/dforce-network" target="_blank" rel="noopener noreferrer">GitHub</a>
                </Changelist>
              </ChangeLanguage>
              <ChangeLanguage
              >
                <span>Governance</span>
                <Changelist className={'showList'}>
                  <a href="https://snapshot.org/#/dforcenet.eth" target="_blank" rel="noopener noreferrer">Snapshot</a>
                  <a href="https://forum.dforce.network/" target="_blank" rel="noopener noreferrer">Forum</a>
                </Changelist>
              </ChangeLanguage>
              <ChangeLanguage
              >
                <span>Community</span>
                <Changelist className={'showList'}>
                  <a href="https://twitter.com/usxprotocol" target="_blank" rel="noopener noreferrer">X</a>
                  <a href="https://t.me/dforcenet" target="_blank" rel="noopener noreferrer">Telegram</a>
                  <a href="https://discord.com/invite/c2PC8SM" target="_blank" rel="noopener noreferrer">Discord</a>
                  <a href="https://medium.com/@usxprotocol" target="_blank" rel="noopener noreferrer">Blog</a>
                </Changelist>
              </ChangeLanguage>
              {/* <ChangeLanguage onClick={showList}>
                <img
                  src={locale === 'en' ? '/images/EN.svg' : '/images/CN.svg'}
                />
                <span>{locale === 'en' ? 'English' : '简体中文'}</span>
                <b></b>
                <Changelist ref={ref} className={open ? '' : 'hide'}>
                  <li onClick={() => changeLanguage('en')}>
                    <img src="/images/EN.svg" />
                    <span>English</span>
                  </li>
                  <li onClick={() => changeLanguage('cn')}>
                    <img src="/images/CN.svg" />
                    <span>简体中文</span>
                  </li>
                </Changelist>
              </ChangeLanguage> */}
            </Nav>
            <Menu onClick={showMenu}>
              <img src="/images/m-menu.svg" alt="" />
            </Menu>
          </TopBar>
          <Slogon>
            <SolgonTitle>An Omni Decentralized Stablecoin Protocol</SolgonTitle>
            <SlogonPara>Scalable, decentralized, vertically-integrated, real-yield stablecoin across all layers.</SlogonPara>
            <LanchApp>
              <a href='https://app.usx.finance' target='_blank' rel="noreferrer">
                {fmt({ id: 'Launch_APP' })}
                <img src="/images/arrow-topright.svg" alt="" srcset="" />
              </a>
            </LanchApp>
          </Slogon>
        </Header>
        <DataList>
          <DataItem>
            <Text>Price($)</Text>
            <Value>{USXPrice ? `${_formatNumberNotCarry(bnToDec(USXPrice,18,4),'',4,4)}` : '...'}</Value>
            {/* {
              USXPrice ?
                <Statistic
                  className="countDown"
                  ref={countRef}
                  countDuration={800}
                  value={_formatNumberNotCarry(bnToDec(USXPrice,18,4),'',4,4)}
                  groupSeparator
                  countUp
                />
                : <DataInit>...</DataInit>
            } */}
            
          </DataItem>
          <DataItem>
            <Text>Total Transactions</Text>
            {
              TotalTransactions ?
                <Statistic
                  className="countDown"
                  ref={countRef2}
                  countDuration={400}
                  value={TotalTransactions}
                  groupSeparator
                  countUp
                />
                : <DataInit>...</DataInit>
            }
            
          </DataItem>
          <DataItem>
            <Text>Total Users</Text>
            {
              TotalUsers ?
                <Statistic
                  className="countDown"
                  ref={countRef3}
                  countDuration={400}
                  value={TotalUsers}
                  groupSeparator
                  countUp
                />
                : <DataInit>...</DataInit>
            }
            
          </DataItem>
          <DataItem>
            <Text>Total Circulation</Text>
            {
              TotalCirculation ?
                <Statistic
                  className="countDown"
                  ref={countRef4}
                  countDuration={200}
                  value={TotalCirculation}
                  groupSeparator
                  countUp
                />
                : <DataInit>...</DataInit>
            }
            
          </DataItem>
        </DataList>
        <SecTitle>Exploring USX</SecTitle>
        <Exploring>
            <ExploringItem className="btn">
              <ETitle>Over-Collateralized</ETitle>
              <EPara>USX maintains its value stability by being over-collateralized by a reserve of mainstream assets, stable assets, and LSD assets.</EPara>
            </ExploringItem>
            <ExploringItem className="btn">
              <ETitle>Omni-Chain</ETitle>
              <EPara>USX serves as a liquidity conduit, facilitating interoperability across different blockchains at enhanced efficiency with minimized costs.</EPara>
            </ExploringItem>
            <ExploringItem className="btn">
              <ETitle>Yield-Bearing</ETitle>
              <EPara>USX provides additional value to holders beyond just price stability, making it potentially attractive options for investors seeking both stability and passive income.</EPara>
            </ExploringItem>
            <ExploringItem className="btn">
              <ETitle>RWA Yields</ETitle>
              <EPara>USX unlocks seamless access to real yields that are backed by RWAs (such as treasury bills) and provides sustainable yields on top of DeFi gains.</EPara>
            </ExploringItem>
            <ExploringItem className="btn">
              <ETitle>Bitcoin-native</ETitle>
              <EPara>Constructed to leverage the robust economic security of Bitcoin as its foundation, boasts interoperability across various Bitcoin Layer2 ecosystems.</EPara>
            </ExploringItem>
            <ExploringItem className="btn">
              <ETitle>AI-Automated</ETitle>
              <EPara>Integrate AI technologies to enhance USX’s functionality, ensuring more efficient trading, higher yields, improved stability, and better management.</EPara>
            </ExploringItem>
        </Exploring>

        <SecTitle>Follow us on</SecTitle>
        <FollowTo>
          <FollowItem href="https://twitter.com/usxprotocol" target="_blank" rel="noreferrer" className="btn">
            <Icon src="/images/TwitterIcon.svg" alt="Twitter" />
            <FPara>Twitter</FPara>
            <Watermark className="nohover" src="/images/Twitter.svg" alt="Twitter"></Watermark>
            <Watermark className="hover" src="/images/_Twitter.svg" alt="Twitter"></Watermark>
          </FollowItem>
          <FollowItem href="https://t.me/dforcenet" target="_blank" rel="noreferrer" className="btn">
            <Icon src="/images/TelegramIcon.svg" alt="Telegram" />
            <FPara>Telegram</FPara>
            <Watermark className="nohover" src="/images/Telegram.svg" alt="Telegram"></Watermark>
            <Watermark className="hover" src="/images/_Telegram.svg" alt="Telegram"></Watermark>
          </FollowItem>
          <FollowItem href="https://medium.com/@usxprotocol" target="_blank" rel="noreferrer" className="btn">
            <Icon src="/images/MediumIcon.svg" alt="Medium" />
            <FPara>Medium</FPara>
            <Watermark className="nohover" src="/images/Medium.svg" alt="Medium"></Watermark>
            <Watermark className="hover" src="/images/_Medium.svg" alt="Medium"></Watermark>
          </FollowItem>
          <FollowItem href="https://discord.com/invite/c2PC8SM" target="_blank" rel="noreferrer" className="btn">
            <Icon src="/images/DiscordIcon.svg" alt="Discord" />
            <FPara>Discord</FPara>
            <Watermark className="nohover" src="/images/Discord.svg" alt="Discord"></Watermark>
            <Watermark className="hover" src="/images/_Discord.svg" alt="Discord"></Watermark>
          </FollowItem>
          <FollowItem href="https://github.com/dforce-network" target="_blank" rel="noreferrer" className="btn">
            <Icon src="/images/GithubIcon.svg" alt="Github" />
            <FPara>GitHub</FPara>
            <Watermark className="nohover" src="/images/Github.svg" alt="Github"></Watermark>
            <Watermark className="hover" src="/images/_Github.svg" alt="Github"></Watermark>
          </FollowItem>
        </FollowTo>
        <Footer>
          <FooterBg src="/images/footerBg.svg" alt=""/>
          <Contact href="mailto:contact@dforce.network" target="_blank" rel="noreferrer">contact@dforce.network</Contact>
          <FooterTop>
            <Navlist>
              <NavItem>
                <Navdl>Docs</Navdl>
                <Navdt><a href="https://docs.usx.finance/" target='_blank' rel="noreferrer">Gitbook</a></Navdt>
                <Navdt><a href="https://github.com/dforce-network" target='_blank' rel="noreferrer">GitHub</a></Navdt>
              </NavItem>
              <NavItem>
                <Navdl>Governance</Navdl>
                <Navdt><a href="https://snapshot.org/#/dforcenet.eth" target='_blank' rel="noreferrer">Snapshot</a></Navdt>
                <Navdt><a href="https://forum.dforce.network/" target='_blank' rel="noreferrer">Forum</a></Navdt>
              </NavItem>
              <NavItem>
                <Navdl>Eco-Partners</Navdl>
                <Navdt><a href="https://dforce.network/" target='_blank' rel="noreferrer">dForce</a></Navdt>
                <Navdt><a href="https://unitus.finance/" target='_blank' rel="noreferrer">Unitus Finance</a></Navdt>
              </NavItem>
              <NavItem>
                <Navdl>Analytics</Navdl>
                <Navdt><a href='https://app.usx.finance/#/Dashboard' target='_blank' rel="noreferrer">Dashboard</a></Navdt>
              </NavItem>
            </Navlist>
          </FooterTop>
        </Footer>
      </Container>
      <Mmenu className={menu ? 'show' : ''} onClick={showMenu}>
        <Mnav>
          <CloseWarp>
            <a>Docs</a>
            <img src="/images/Close.svg" alt="Close" width="18" onClick={() => { setMenu(false) }} />
          </CloseWarp>
          <ChildList>
            <a href="https://docs.usx.finance/" target="_blank" rel="noopener noreferrer">GitBook</a>
            <a href="https://github.com/dforce-network" target="_blank" rel="noopener noreferrer">GitHub</a>
          </ChildList>
          <a>Governance</a>
          <ChildList>
            <a href="https://snapshot.org/#/dforcenet.eth" target="_blank" rel="noopener noreferrer">Snapshot</a>
            <a href="https://forum.dforce.network/" target="_blank" rel="noopener noreferrer">Forum</a>
          </ChildList>
          <a>Community</a>
          <ChildList>
            <a href="https://twitter.com/usxprotocol" target="_blank" rel="noopener noreferrer">X</a>
            <a href="https://t.me/dforcenet" target="_blank" rel="noopener noreferrer">Telegram</a>
            <a href="https://discord.com/invite/c2PC8SM" target="_blank" rel="noopener noreferrer">Discord</a>
            <a href="https://medium.com/@usxprotocol" target="_blank" rel="noopener noreferrer">Blog</a>
          </ChildList>
        </Mnav>
      </Mmenu>
    </>
  );
};
export default Home;

const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  padding: 0 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 400;
  &::before{
    content: "";
    position: absolute;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    width: 100%;
    height: calc( (100vw * 843) / 1734);
    background-image: url("/images/PCbg.png");
    background-size: cover;
    z-index: -1;
  }
  /* &::after{
    content: "";
    position: absolute;
    left: 50%;
    top: 42px;
    transform: translateX(-50%);
    width: 100%;
    height: 17px;
    background-image: url("/images/line.svg");
    background-size: 100% auto;
    z-index: -1;
  } */
  @media (max-width: 1360px) {
    justify-content: space-between;
    &::before{
      width: 100%;
      height: calc( (100vw * 365) / 750);
      background-image: url("/images/H5bg.png");
      top: 0;
    }
    /* &::after{
      width: 100%;
      height: calc( (100vw * 17) / 1919);
      top: 38px;
    } */
  }
  
  /*&::before{
    content: "";
    position: absolute;
    left: 50%;
    top: 100px;
    transform: translateX(-50%);
    width: 1360px;
    height: 625px;
    background-image: url("/images/topBg.svg");
    background-size: cover;
    z-index: -1;
  }
  &::after{
    content: "";
    position: absolute;
    left: 50%;
    top: 52px;
    transform: translateX(-50%);
    width: 100%;
    height: 17px;
    background-image: url("/images/line.svg");
    background-size: 100% auto;
    z-index: -1;
  }
  @media (max-width: 1360px) {
    justify-content: space-between;
    &::before{
      width: 100%;
      height: calc( (100vw * 625) / 1360);
      top: 50px;
    }
    &::after{
      width: 100%;
      height: calc( (100vw * 17) / 1919);
      top: 38px;
    }
  }*/
`;

const Menu = styled.div`
display: none;
@media (max-width: 1360px) {
  display: block;
  width: 26px;
  height: 26px;
  margin-left: 8px;
  img {
    width: 26px;
    height: 26px;
  }
}
`;
const Mnav = styled.nav`
  display: none;
  @media (max-width: 1360px) {
    display: flex;
    flex-direction: column;
    width: calc( 100% - 24px);
    position: absolute;
    right: 12px;
    top: 38px;
    padding: 16px;
    border-radius: 12px;
    border: 1px solid #2C2C2C;
    background: #0F0F0F;
    box-sizing: border-box;
    a {
      font-size: 12px;
      font-weight: 600;
      line-height: 36px;
    }
  }
`;
const CloseWarp = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const ChangeLanguage = styled.li`
  cursor: pointer;
  /* margin-right: 24px; */
  position: relative;
  background: transparent;
  border: none;
  font-size: 16px;
  font-weight: 400;
  .showList {
    display: none;
  }
  &:hover {
    .showList {
      display: flex;
    }
  }
`
const Changelist = styled.ul`
  &.hide {
    display: none;
  }
  position: absolute;
  left: 50%;
  top: 60px;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  border: 1px solid #2C2C2C;
  background: #000000;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px 16px;
  a {
    line-height: 36px;
    font-weight: 400;
  }
`;
const ChildList = styled.div`
 display: flex;
 flex-direction: column;
  padding: 16px 12px;
  border-radius: 8px;
background: #000000;
  a{
    font-size: 12px;
    font-weight: 500;
    line-height: 26px;
  }
;
`
const Mmenu = styled.section`
  display: none;
  @media (max-width: 1360px) {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
    &.show {
      display: flex;
      flex-direction: column;
    }
  }
`;
const Header = styled.section`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  @media (max-width: 1360px) {
    background-position: center;
  }
`;
const TopBar = styled.header`
  width: 100%;
  height: 60px;
  line-height: 60px;
  /* padding: 0 32px; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 1360px) {
    width: 100%;
    height: 38px;
    line-height: 38px;
    padding: 0;
  }
  a{
    display: flex;
    &.logo{
      margin-left: -16px;
    }
  }
`;
const Log = styled.img`
  width: 110px;
  height: 24px;
  @media (max-width: 1360px) {
    width: 83px;
    height: 22px;
  }
`;
const Slogon = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 1101px;
  padding: 160px 0;
  @media (max-width: 1360px) {
    width: 100%;
    padding: 46px 31px 50px;
  }
`
const SolgonTitle = styled.p`
  font-size: 100px;
  font-weight: 700;
  line-height: 120px;
  text-align: center;
  color: #fff;
  margin-bottom: 30px;
  @media (max-width: 1360px) {
    font-size: 32px;
    line-height: 50px;
    margin-bottom: 15px;
  }
`
const SlogonPara = styled.p`
  font-size: 20px;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 48px;
  @media (max-width: 1360px) {
    font-size: 10px;
    line-height: 16px;
    margin-bottom: 24px;
    text-align: center;
  }
`
const LanchApp = styled.p`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  font-weight: 600;
  a {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: max-content;
    padding: 16px 24px;
    cursor: pointer;
    font-size: 18px;
    color: #000;
    background-color: #FF5C00;
    gap: 6px;
    img {
      display: inline-block;
      margin-top: 2px;
      width: 20px;
      height: 20px;
    }
  };
  @media (max-width: 1360px) {
    width: 100%;
    margin: 0 auto;
    a {
      font-size:12px;
      padding:8px 12px;
      img {
        display: inline-block;
        width: 14px;
        height: 14px;
      }
    };
  }
`
const Nav = styled.nav`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  font-size: 16px;
  font-weight: 400;
  z-index: 2;
  li {
    padding:0 16px;
  }
  @media (max-width: 1360px) {
    display: none;
  }
`;
const DataList = styled.ul`
  display: flex;
  justify-content: center;
  width: 1360px;
  @media (max-width: 1360px) {
    flex-wrap: wrap;
    width: 100%;
    margin: 20px 0;
  }
`
const DataItem = styled.li`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  padding: 64px 0px;
  gap: 16px;
  border-right:1px solid #2C2C2C;
  border-bottom:1px solid #2C2C2C;
  &:last-child{
    border-right: 0;
  }
  @media (max-width: 1360px) {
    width: 50%;
    flex: none;
    padding: 32px 0;
    gap: 12px;
    &:nth-child(2){
    border-right: 0;
  }
  }
`
const DataInit = styled.p`
  font-size: 48px;
  line-height: 56px;
  font-weight: 700;
  color: #fff;
  @media (max-width: 1360px) {
    font-size: 26px;
    line-height: 26px;
  }
`
const Value = styled.p`
  font-size: 48px;
  line-height: 56px;
  font-weight: 700;
  color: #fff;
  @media (max-width: 1360px) {
    font-size: 26px;
    line-height: 26px;
  }
`
const Text = styled.p`
font-size: 20px;
font-weight: 400;
line-height: 20px;
@media (max-width: 1360px) {
  font-size: 12px;
  line-height: 12px;
}
;
`
const SecTitle = styled.p`
width: 1360px;
font-size: 48px;
font-weight: 700;
line-height: 64px;
text-align: left;
color: #FF5C00;
padding: 60px 0px;
@media (max-width: 1360px) {
  width: 100%;
  padding: 30px 0;
  font-size: 24px;
  line-height: 32px;
}
`
const Exploring = styled.ul`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  row-gap: 37px;
  column-gap: 24px;
  width: 1360px;
  @media (max-width: 1360px) {
    width: 100%;
    gap: 12px;
    flex-direction: column;
  }
`
const ExploringItem = styled.li`
  display: flex;
  flex-direction: column;
  width: 32%;
  padding: 24px;
  border: 1px solid #292929;
  @media (max-width: 1360px) {
    width: 100%;
    padding: 15px;
  }
`
const ETitle = styled.p`
  padding: 16px 0;
  font-size: 28px;
  font-weight: 700;
  line-height: 28px;
  color: #fff;
  @media (max-width: 1360px) {
    font-size: 17px;
    line-height: 17px;
  }
`
const EPara = styled.p`
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
  @media (max-width: 1360px) {
    font-size: 12px;
    line-height: 20px;
  }
`
const FollowTo = styled.div`
  display: flex;
  justify-content: space-between;
  width: 1360px;
  margin-bottom: 69px;
  @media (max-width: 1360px) {
    width: 100%;
    margin-bottom: 35px;
    flex-wrap: wrap;
    row-gap: 15px;
    column-gap: 12px;
  }
`
const FollowItem = styled.a`
  display: flex;
  flex-direction: column;
  width: 252px;
  padding: 40px 32px;
  gap: 16px;
  border: 1px solid #292929;
  position: relative;
  @media (max-width: 1360px) {
    padding: 20px 16px;
    gap: 8px;
    width:48%;
  }
`
const Icon = styled.img`
    width: 60px;
    height: 60px;
    @media (max-width: 1360px) {
      width: 30px;
      height: 30px;
    }
`
const FPara = styled.p`
  font-size: 18px;
  font-weight: 400;
  line-height: 18px;
  @media (max-width: 1360px) {
      font-size: 10px;
      line-height: 10px;
    }
`
const Watermark = styled.img`
    position: absolute;
    right: 16px;
    bottom: 17px;
    width: 64px;
    height: 64px;
    z-index: -1;
    @media (max-width: 1360px) {
      width: 40px;
      height: 40px;
      right: 8px;
      bottom: 9px;
    }
`
const Footer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 20px 0px 80px 0px;
  gap: 24px;
  background: #000000;
  @media (max-width: 1360px) {
    width: 100%;
    flex-direction: column;
    padding:10px 0px 40px;
    gap: 12px;
  }
`
const FooterBg = styled.img`
  width: 908px;
  height: 83px;
  @media (max-width: 1360px) {
    width: 100%;
    height: calc( 100% * 83 / 908);
  }
`
const Contact = styled.a`
font-size: 16px;
font-weight: 400;
line-height: 36px;
color:#A3ADBE;
@media (max-width: 1360px) {
  font-size: 10px;
  line-height: 18px;
}
`
const FooterTop = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: 1360px) {
    width: 100%;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 20px;
  }
`

const Navlist = styled.ul`
  display: flex;
  justify-content: space-between;
  gap: 160px;
  @media (max-width: 1360px) {
    width: 100%;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 16px;
  }
`
const NavItem = styled.li`
  display: flex;
  flex-direction: column;
  @media (max-width: 1360px) {
    width: 47%;
  }
`
const Navdl = styled.dl`
font-size: 20px;
font-weight: 700;
line-height: 20px;
margin-bottom: 16px;
color: #fff;
@media (max-width: 1360px) {
  font-size: 10px;
  line-height: 10px;
}
`
const Navdt = styled.dt`
font-size: 16px;
font-weight: 400;
line-height: 36px;
a{
  color: rgba(121, 126, 132, 1);
}
@media (max-width: 1360px) {
  font-size: 10px;
  line-height: 18px;
}
`

const en_US = {
  Launch_APP: 'Launch App',
  ComingSoon:'Coming Soon',
  JoinTwitter:'Join Twitter',
  Twitter:'Twitter',
  Medium:'Medium',
  Telegram:'Telegram',
  WeChat:'WeChat'

};
export default en_US;
